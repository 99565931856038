import { Box, Stack, TextField, Typography } from '@mui/material'
import ButtonWithLoading from 'components/button_with_loading'
import { useDocumentTitle } from 'hooks'
import { useContext, useState } from 'react'
import { ExportToCsv } from 'export-to-csv-fix-source-map'
import { constructSku } from 'models'
import { get_and_increment_next_offline_crediting_sku } from 'api/clothes_service'
import { UnauthorisedError } from 'errors'
import { UserContext } from 'base'

const PRICES = [5, 8, 10, 12, 15, 18, 20, 22, 25, 28, 30, 35, 40, 50]
interface PriceToLabel {
  [key: number]: string
}

const initialPriceToLabel = PRICES.reduce((prev: PriceToLabel, current) => {
  prev[current] = '0'
  return prev
}, {})

const SkuLabels = () => {
  useDocumentTitle('Download SKU Labels')
  const user = useContext(UserContext)

  const [priceToLabelQty, setPriceToLabelQty] = useState(initialPriceToLabel)
  const totalLabelQty = Object.values(priceToLabelQty).reduce(
    (prev: number, current: string) => prev + Number(current),
    0
  )
  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h3">Download SKU Labels</Typography>
          <Typography variant="subtitle1">
            Download clothes crediting labels in CSV format for printing.
          </Typography>
        </Stack>
        <Box
          component="form"
          onSubmit={async (event) => {
            setIsGenerating(true)
            event.preventDefault()

            let nextSku
            try {
              nextSku = await get_and_increment_next_offline_crediting_sku(
                totalLabelQty
              )
            } catch (e: any) {
              if (e instanceof UnauthorisedError) {
                return user.setLoggedOut()
              }
              setIsGenerating(false)
              return
            }
            const data = []
            for (const price of PRICES) {
              for (let i = 0; i < Number(priceToLabelQty[price]); i++) {
                data.push({
                  sku: constructSku(nextSku),
                  price: price,
                })
                nextSku++
              }
            }
            const csvExporter = new ExportToCsv({
              filename: 'thryft_clothes_crediting_labels',
              useKeysAsHeaders: true,
            })
            csvExporter.generateCsv(data)
            setIsGenerating(false)
          }}
        >
          <Stack spacing={3} alignItems="flex-start">
            {PRICES.map((price) => (
              <TextField
                required
                type="number"
                inputProps={{ min: 0 }}
                key={price}
                label={`No. of $${price} labels`}
                value={priceToLabelQty[price]}
                onChange={(event) =>
                  setPriceToLabelQty((prev) => ({
                    ...prev,
                    [price]: event.target.value,
                  }))
                }
              />
            ))}
            <ButtonWithLoading
              type="submit"
              variant="contained"
              disabled={totalLabelQty === 0}
              isLoading={isGenerating}
            >
              Download Labels
            </ButtonWithLoading>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default SkuLabels
