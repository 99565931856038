import {
  BrandTier,
  ClothingDataDB,
  ClothingItem,
  ClothingItemWithPrice,
  ClothingItemWithPriceAndSku,
  GetAndIncrementNextBoxWhjIdResponse,
  GetAndIncrementNextOfflineCreditingSkuResponse,
} from 'models'
import {
  APIError,
  AlreadyExistsError,
  NotFoundError,
  UnauthorisedError,
} from 'errors'
import ENV from 'environment'
import Cookies from 'js-cookie'

const BASE_URL = ENV.IS_PROD
  ? process.env.REACT_APP_CLOTHES_SERVICE_URL_PROD
  : ENV.IS_STAGING
  ? process.env.REACT_APP_CLOTHES_SERVICE_URL_STAGING
  : process.env.REACT_APP_CLOTHES_SERVICE_URL_LOCAL

const getCSRFToken = () => Cookies.get('csrf_access_token')!

const requests = {
  get: async (url: string, params?: Object) => {
    const _url = new URL(`${BASE_URL}${url}`)

    if (params !== undefined) {
      for (const [name, value] of Object.entries(params)) {
        if (value !== null) {
          _url.searchParams.append(name, value)
        }
      }
    }

    return await fetch(_url, {
      method: 'GET',
      credentials: 'include',
    })
  },

  jsonRequest: async (url: string, body: object | undefined, method: string) =>
    await fetch(`${BASE_URL}${url}`, {
      method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCSRFToken(),
        // Authorization: `Bearer ${localStorage.getItem('jwt')}`,
      },
      credentials: 'include',
    }),

  post: (url: string, body?: object) => requests.jsonRequest(url, body, 'POST'),

  patch: (url: string, body?: object) =>
    requests.jsonRequest(url, body, 'PATCH'),

  put: (url: string, body: object) => requests.jsonRequest(url, body, 'PUT'),

  delete: (url: string, body?: object) =>
    requests.jsonRequest(url, body, 'DELETE'),
}

// NOTE: API calls are in snake case to align with Python convention.
// This makes the function names easily searchable.

export const login_user = async (email: string, password: string) => {
  const response = await requests.post('/login', { email, password })
  if (response.status === 401)
    throw new UnauthorisedError('Wrong email and/or password')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
  // localStorage.setItem('jwt', json.jwt)
}

export const logout_user = async () => {
  const response = await requests.post('/logout')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
  // localStorage.removeItem('jwt')
}

export const change_password = async (
  current_password: string,
  new_password: string,
  new_password_repeat: string
) => {
  const response = await requests.post('/change-password', {
    current_password,
    new_password,
    new_password_repeat,
  })
  if (response.status === 401)
    throw new UnauthorisedError(
      'User unauthenticated or wrong current password'
    )
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const get_clothing_item_pricing = async (clothingItem: ClothingItem) => {
  const response = await requests.post(
    '/clothes/crediting/items/pricing',
    clothingItem
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
  const json = await response.json()
  const clothingItemWithPrice = json as ClothingItemWithPrice
  return clothingItemWithPrice
}

export const replace_clothing_item = async (
  upload_id: string,
  sku: string,
  clothingItem: ClothingItemWithPriceAndSku
) => {
  const response = await requests.put(
    `/clothes/crediting/uploads/${upload_id}/items/${sku}`,
    clothingItem
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404) throw new NotFoundError('SKU does not exist.')
  if (response.status === 409)
    throw new AlreadyExistsError('SKU already exists.')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const delete_clothing_item = async (upload_id: string, sku: string) => {
  const response = await requests.delete(
    `/clothes/crediting/uploads/${upload_id}/items/${sku}`
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404) throw new NotFoundError('SKU does not exist.')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const get_crediting_uploads_in_progress = async () => {
  const response = await requests.get(`/clothes/crediting/uploads/in-progress`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)

  const json = await response.json()
  const clothingUploads = json as Array<object>

  return clothingUploads.map((clothingUpload) =>
    ClothingDataDB.fromJson(clothingUpload)
  )
}

export const get_crediting_uploads_partial = async () => {
  const response = await requests.get(`/clothes/crediting/uploads/partial`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)

  const json = await response.json()
  const clothingUploads = json as Array<object>

  return clothingUploads.map((clothingUpload) =>
    ClothingDataDB.fromJson(clothingUpload)
  )
}

export const get_crediting_uploads_for_review = async () => {
  const response = await requests.get(`/clothes/crediting/uploads/review`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')

  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)

  const json = await response.json()
  const clothingUploads = json as Array<object>

  return clothingUploads.map((clothingUpload) =>
    ClothingDataDB.fromJson(clothingUpload)
  )
}

export const get_crediting_uploads_completed_paginated = async (
  sort: string | null,
  before: string | null,
  before_id: string | null,
  after: string | null,
  after_id: string | null
) => {
  const response = await requests.get(
    '/clothes/crediting/uploads/completed/paginated',
    {
      sort,
      before,
      before_id,
      after,
      after_id,
    }
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)

  const json = await response.json()

  const clothingUploads: Array<object> = json.uploads
  const uploads: ClothingDataDB[] = clothingUploads.map((clothingUpload) =>
    ClothingDataDB.fromJson(clothingUpload)
  )
  const prev: string | null = json.prev
  const prev_id: string | null = json.prev_id?.$oid ?? null
  const next: string | null = json.next
  const next_id: string | null = json.next_id?.$oid ?? null

  return { prev, prev_id, next, next_id, uploads }
}

export const get_crediting_uploads_completed = async () => {
  const response = await requests.get(`/clothes/crediting/uploads/completed`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)

  const json = await response.json()
  const clothingUploads = json as Array<object>

  return clothingUploads.map((clothingUpload) =>
    ClothingDataDB.fromJson(clothingUpload)
  )
}

export const get_clothes_crediting_upload = async (upload_id: string) => {
  const response = await requests.get(`/clothes/crediting/uploads/${upload_id}`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404)
    throw new NotFoundError(`Upload with id ${upload_id} does not exist.`)
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)

  const json = await response.json()
  const clothingData = ClothingDataDB.fromJson(json)
  return clothingData
}

export const delete_clothes_crediting_upload = async (upload_id: string) => {
  const response = await requests.delete(
    `/clothes/crediting/uploads/${upload_id}`
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404)
    throw new NotFoundError(`Upload with id ${upload_id} does not exist.`)
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const approve_crediting_upload = async (upload_id: string) => {
  const response = await requests.patch(
    `/clothes/crediting/uploads/approved/${upload_id}`
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404)
    throw new NotFoundError(`Upload with id ${upload_id} does not exist.`)
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const mark_crediting_upload_as_complete = async (upload_id: string) => {
  const response = await requests.patch(
    `/clothes/crediting/uploads/partial/${upload_id}`
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404)
    throw new NotFoundError(`Upload with id ${upload_id} does not exist.`)
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const get_and_increment_next_offline_crediting_sku = async (
  quantity: number
) => {
  const response = await requests.post(`/clothes/crediting/skus/${quantity}`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
  const json = await response.json()
  const model = json as GetAndIncrementNextOfflineCreditingSkuResponse
  return model.next_sku
}

export const get_and_increment_next_box_whj_id = async (quantity: number) => {
  const response = await requests.post(
    `/clothes/crediting/box-whj-id/${quantity}`
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
  const json = await response.json()
  const model = json as GetAndIncrementNextBoxWhjIdResponse
  return model.next_box_id
}

export const search_brand_tier = async (searchTerm: string) => {
  const response = await requests.get(
    `/clothes/brand-tier?${new URLSearchParams({ query: searchTerm })}`
  )
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
  const json = await response.json()
  const brandTiers = json as Array<BrandTier>
  return brandTiers
}

export const edit_brand_tier = async (brandTier: BrandTier) => {
  const response = await requests.patch(`/clothes/brand-tier/edit`, brandTier)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  if (response.status === 404)
    throw new NotFoundError(
      `Brand tier with brand ${brandTier.brand} does not exist.`
    )
  if (response.status !== 200)
    throw new APIError(`${response.status} ${response.statusText}`)
}

export const check_is_logged_in = async () => {
  const response = await requests.get(`/jwt-check`)
  return response.status === 200
}

export const check_is_supervisor_or_above = async () => {
  const response = await requests.get(`/jwt-check-supervisor-required`)
  if (response.status === 401) throw new UnauthorisedError('User not logged in')
  return response.status === 200
}
